<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					Mobile Payment Solution
				</div>
				<div class="cen-content">
					Overall solution to start your mobile payment service.
				</div>
				<div class="cen-line">

				</div>
				<div class="cen-msg">
					With the development of mobile internet technology and the popularization of smartphones, the
					convenient, fast, and secure mobile payment becoming common.
					<br />
					<br />
					<br />
					We provide an overall mobile payment solution to help our partner start their own mobile payment
					business.
				</div>
			</div>
		</div>
		<div class="page_2">
			<div class="page_2_1">
				<div class="page_2_1_top">
					<div class="top-title">
						One-stop mobile payment solution
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_2_1_bot">
					<div class="page_2_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_1.png" alt="" />
						</div>
						<div class="item-name">
							Multiple channels
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Bank card
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								3rd party account
							</div>
						</div>
					</div>
					<div class="page_2_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_2.png" alt="" />
						</div>
						<div class="item-name">
							Multiple channels
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								QR & NFC payment
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Online payment
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Transfer
							</div>
						</div>
					</div>
					<div class="page_2_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_3.png" alt="" />
						</div>
						<div class="item-name">
							Multiple channels
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Quick start platform/App
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Unified API platform
							</div>
						</div>
					</div>
					<div class="page_2_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_4.png" alt="" />
						</div>
						<div class="item-name">
							Multiple channels
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Real-time business status
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Business strategy
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon" style="background-color: #fff;">

							</div>
							<div class="right-text">
								optimization
							</div>
						</div>
					</div>
					<div class="page_2_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_5.png" alt="" />
						</div>
						<div class="item-name">
							Multiple channels
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Encryption and Digital
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon" style="background-color: #fff;">

							</div>
							<div class="right-text">
								signature
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Risk assessment
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="page_3">
			<div class="page_3_1">
				<div class="page_3_1_top">
					<div class="top-title">
						Typical use scenarios
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_3_1_bot">
					<div class="page_3_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_6.png" alt="" />
						</div>
						<div class="item-name">
							Retail
						</div>
						<div class="item-content">
							Market, shop, supermarket, shopping mall
						</div>
					</div>
					<div class="page_3_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_7.png" alt="" />
						</div>
						<div class="item-name">
							Food & Beverage
						</div>
						<div class="item-content">
							Foodstand, restaurant, drive-thru
						</div>
					</div>
					<div class="page_3_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_8.png" alt="" />
						</div>
						<div class="item-name">
							Transportation
						</div>
						<div class="item-content">
							Bus, metro, self-pumping and EV charging
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="page_4">
		</div>
		<div class="page_5">
			<div class="page_5_1">
				<div class="page_5_1_top">
					<div class="top-title">
						- Food Stands
						<br />
						- Small Restaurants
					</div>
				</div>
				<div class="page_5_1_bot">
					<div class="page_5_1_bot_left">
						<div class="page_5_img">

						</div>
					</div>
					<div class="page_5_1_bot_right">
						<div class="food-list">
							<div class="food-item">
								<div class="left-img">
									<img src="../../assets/images/soluts/solut0_10.png" alt="" />
								</div>
								<div class="right-msg">
									<div class="title">
										Without Interruption
									</div>
									<div class="content">
										Customer scan to pay, the owner can keep preparing the foods, no need to pay
										extra action to collect the money.
									</div>
								</div>
							</div>
							<div class="food-item">
								<div class="left-img">
									<img src="../../assets/images/soluts/solut0_11.png" alt="" />
								</div>
								<div class="right-msg">
									<div class="title">
										Income Announcement
									</div>
									<div class="content">
										Every income will have voice announcement, to make sure it well received. No
										need to check it manually, help preventing the fraud.
									</div>
								</div>
							</div>
							<div class="food-item">
								<div class="left-img">
									<img src="../../assets/images/soluts/solut0_12.png" alt="" />
								</div>
								<div class="right-msg">
									<div class="title">
										Income Report
									</div>
									<div class="content">
										Income report by hour, day, week, month, year.
										customer consists analyze.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="page_6">
			<div class="page_6_1">
				<div class="page_6_left">
					<div class="left-title">
						Food Delivery & In-store
						<br />
						Ordering
					</div>
					<div class="left-list">

						<div class="item-right">
							<div class="item-title">
								<div class="item-left">

								</div>
								Receiving Online & Offline Order
							</div>
							<div class="item-msg">
								It can automatically receive order from different channels, such as takeout service
								providers, in-store order system.
							</div>
						</div>
					</div>
					<div class="left-list">
						<div class="item-right">
							<div class="item-title">
								<div class="item-left">

								</div>
								Announcement & Printing
							</div>
							<div class="item-msg">
								When new order received, it can give voice announcement and print receipt. Can place
								multiple printers to print different types of receipt.
							</div>
						</div>
					</div>
				</div>
				<div class="page_6_right">

				</div>
			</div>
		</div>
		<div class="page_7">
			<div class="page_7_1">
				<div class="page_7_1_top">
					<div class="top-title">
						Overall Mobile Payment Solution
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_7_1_bot">
				</div>

			</div>
		</div>
		<div class="page_8">
			<div class="page_8_1">
				<div class="page_8_1_top">
					<div class="top-title">
						Mobile payment methods
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_8_1_bot">
					<div class="page_8_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_15.png" alt="" />
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Merchant present the QR code
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Lower cost
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Security concern: risk of tamper
							</div>
						</div>
					</div>
					<div class="page_8_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_16.png" alt="" />
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Consumer tap to pay
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Better user experience
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Good security
							</div>
						</div>
					</div>
					<div class="page_8_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_17.png" alt="" />
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Merchant present the QR code
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Lower cost
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Security concern: risk of tamper
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<div class="page_9">
			<div class="page_9_1">
				<div class="page_9_1_top">
					<div class="top-title">
						Payment Terminal Products
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_9_1_bot">
					<img src="../../assets/images/soluts/solut0_19.png" alt="" />
					<img src="../../assets/images/soluts/solut0_20.png" alt="" />
					<img src="../../assets/images/soluts/solut0_21.png" alt="" />
					<img src="../../assets/images/soluts/solut0_22.png" alt="" />
					<img src="../../assets/images/soluts/solut0_23.png" alt="" />
					<img src="../../assets/images/soluts/solut0_24.png" alt="" />
				</div>

			</div>
		</div>

		<div class="page_10">
			<div class="page_10_1">
				<div class="page_10_1_top">
					<div class="top-title">
						A well-designed system to help you
						<br />
						achieve business success.
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_10_1_bot">
					<div class="page_10_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_25.png" alt="" />
						</div>
						<div class="item-name">
							Convenient
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Consumer：Easy sign up, easy
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon" style="background-color: #fff;">

							</div>
							<div class="right-text">
								payment, easy access
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Quick start platform and App
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Unified API platform
							</div>
						</div>
					</div>
					<div class="page_10_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_26.png" alt="" />
						</div>
						<div class="item-name">
							Secure
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								RSA encryption
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Digital signature
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Automatic risk assessment
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								One-time password
							</div>
						</div>
					</div>
					<div class="page_10_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_27.png" alt="" />
						</div>
						<div class="item-name">
							Reliable
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Multi-layer data backup
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Load balancer to prevent SPOF
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Fault tolerant design
							</div>
						</div>
					</div>
					<div class="page_10_item">
						<div class="top_img">
							<img src="../../assets/images/soluts/solut0_28.png" alt="" />
						</div>
						<div class="item-name">
							Flexible
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Configurable design (functions

							</div>
						</div>
						<div class="item-list">
							<div class="left-icon" style="background-color: #fff;">

							</div>
							<div class="right-text">
								on and off)
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Support plug-in development
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon" style="background-color: #fff;">

							</div>
							<div class="right-text">
								to realize dynamic extension
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon">

							</div>
							<div class="right-text">
								Decoupled architecture to
							</div>
						</div>
						<div class="item-list">
							<div class="left-icon" style="background-color: #fff;">

							</div>
							<div class="right-text">
								support easy customization.
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="page_11">
			<div class="page_11_1">
				<div class="page_11_1_top">
					<div class="top-title">
						Overall Mobile Payment Solution
					</div>
					<div class="bot-line">

					</div>
				</div>
				<div class="page_11_1_bot">
					<div class="page_11_left">
						<div class="left-list">
							<div class="item-title">
								Consult
							</div>
							<div class="item-list">
								<div class="left-icon">

								</div>
								<div class="right-text">
									Requirement survey
								</div>
							</div>
							<div class="item-list">
								<div class="left-icon">
							
								</div>
								<div class="right-text">
									Customize solution
								</div>
							</div>
						</div>
						<div class="left-list">
							<div class="item-title">
								Trial
							</div>
							<div class="item-list">
								<div class="left-icon">
						
								</div>
								<div class="right-text">
									Demo version
								</div>
							</div>
							<div class="item-list">
								<div class="left-icon">
							
								</div>
								<div class="right-text">
									PoC support
								</div>
							</div>
						</div>
						<div class="left-list">
							<div class="item-title">
								Customization
							</div>
							<div class="item-list">
								<div class="left-icon">
						
								</div>
								<div class="right-text">
									Language localization
								</div>
							</div>
							<div class="item-list">
								<div class="left-icon">
							
								</div>
								<div class="right-text">
									Software modification
								</div>
							</div>
						</div>
						<div class="left-list">
							<div class="item-title">
								Deploy
							</div>
							<div class="item-list">
								<div class="left-icon">
						
								</div>
								<div class="right-text">
									LDeploy support
								</div>
							</div>
							<div class="item-list">
								<div class="left-icon">
							
								</div>
								<div class="right-text">
									Documentation Training
								</div>
							</div>
						</div>
						<div class="left-list" style="width: 460px;" >
							<div class="item-title">
								Long term support
							</div>
							<div class="item-list">
								<div class="left-icon">
						
								</div>
								<div class="right-text">
									Technical support
								</div>
							</div>
							<div class="item-list">
								<div class="left-icon">
							
								</div>
								<div class="right-text">
									Iteration
								</div>
							</div>
						</div>
					</div>
					<div class="page_11_right">

					</div>
				</div>


			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'about',
		components: {
			PageBot,
			PageNav
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
		},
		watch: {

			screenWidth: {
				handler: function(val) {
					// console.log("当前屏幕尺寸--" + val)
					// console.log(this.list_status)
					if (val != null && val < 742) {
						this.list_status = false
					} else {
						this.list_status = true
					}
					// console.log(this.list_status)
				},
				immediate: true,
				deep: true
			},
		},

		mounted() {
			window.addEventListener("scroll", this.watchScroll); // 滚动吸顶
			this.screenWidth = document.body.clientWidth
			window.onresize = () => {
				return (() => {
					this.screenWidth = document.body.clientWidth // 监听屏幕宽度
				})()
			}

		},
		data() {
			return {
				avtive_nav: "",
				show_device_detail: false,
				device_detail: {},
				currentSwiper: null,
				currentSwipers: null,
				acctive_img: require("../../assets/images/about/box_1.png"),
				show_top: false,
				data_list: [{
						id: 0,
						img: require("../../assets/images/about/box_1.png"),
						acctive: true
					}, {
						id: 1,
						img: require("../../assets/images/about/box_2.png"),
						acctive: false
					},
					{
						id: 2,
						img: require("../../assets/images/about/box_3.png"),
						acctive: false
					},
					{
						id: 3,
						img: require("../../assets/images/about/box_4.png"),
						acctive: false
					},

				],
				flag_status: true,
				navBarFixed: false,
				screenWidth: null,
				list_status: true,
				avtive_img: 0
			}
		},
		methods: {
			// 鼠标移入-导航
			enter_img(item) {
				this.acctive_img = item.img;
				for (var i = 0; i < this.data_list.length; i++) {
					if (item.id == this.data_list[i].id) {
						this.data_list[i].acctive = true;
					} else {
						this.data_list[i].acctive = false;
					}
				}
			},
			// 滚动吸顶
			watchScroll() {
				// 当滚动超过 90 时，实现吸顶效果
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 90) {
					this.navBarFixed = true;
				} else {
					this.navBarFixed = false;
				}
			},
			go_index() {
				window.location.href = "/";
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}
	html{
		min-width: 1380px;
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 100vh;
		max-height: 950px;
		object-fit: cover; // 保持图片缩放时候不变形
		background-image: url("../../assets/images/soluts/solut0_bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; // 子元素无法被选中
	}


	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #FFFFFF;

		.cen-text {
			margin-top: 30vh;
			margin-left: 15%;
			// background-color: aqua;
			font-size: 60px;
		}

		.cen-line {
			width: 72px;
			height: 4px;
			margin-top: 30px;
			margin-left: 15%;
			background-color: #FFFFFF;
		}

		.cen-content {
			margin-top: 10px;
			margin-left: 15%;
			font-size: 24px;
		}

		.cen-msg {
			margin-top: 10px;
			margin-left: 15%;
			font-size: 18px;
			width: 500px;
		}


	}








	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
	
		.page_2 {
			width: 100%;
			min-width: 1360px;
			background: #FFFFFF;
			// height: 9480px;
			// object-fit: cover; // 保持图片缩放时候不变形
			// background-image: url("../../assets/images/soluts/solut0.png");
			// background-repeat: no-repeat;
			// background-size: cover;
			// background-position: center center;
			// -webkit-user-select: none;
			// -moz-user-select: none;
			// -ms-user-select: none;
		}
	
		.page_2_1 {
			width: 1360px;
			margin: 0 auto;
			padding-bottom: 200px;
	
	
			.page_2_1_top {
				margin-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_2_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				justify-content: space-between;
	
				.page_2_item {
					width: 260px;
					height: 340px;
					background: #FFFFFF;
					border: 1px solid #E5E5E5;
					display: flex;
					flex-direction: column;
	
					.top_img {
						width: 100%;
						height: 180px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
	
					.item-name {
						width: 82%;
						margin: 0 auto;
						margin-bottom: 20px;
						font-size: 24px;
						color: #2F2F2F;
						text-align: left;
					}
	
					.item-list {
						width: 82%;
						margin: 0 auto;
						font-size: 16px;
						color: #2F2F2F;
						display: flex;
						align-items: center;
						margin-bottom: 5px;
	
						.left-icon {
							width: 8px;
							height: 8px;
							margin-right: 10px;
							border-radius: 50%;
							background-color: #D8D8D8;
						}
	
						.right-text {}
					}
				}
	
	
	
			}
	
		}
	
		.page_3 {
			background: #FAFAFA;
			padding-bottom: 200px;
	
			.page_3_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_3_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_3_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				justify-content: space-between;
	
				.page_3_item {
					width: 400px;
					// height: 340px;
					display: flex;
					flex-direction: column;
	
					.top_img {
						width: 100%;
						height: 260px;
						display: flex;
						align-items: center;
						justify-content: center;
	
						img {
							width: 100%;
							height: 260px;
							object-fit: cover;
						}
					}
	
					.item-name {
						width: 82%;
						margin: 0 auto;
						margin-bottom: 10px;
						margin-top: 30px;
						font-size: 24px;
						color: #2F2F2F;
						text-align: center;
					}
	
					.item-content {
						text-align: center;
						font-size: 14px;
						color: #ACACAC;
					}
				}
	
	
	
			}
	
		}
	
	
	
		.page_4 {
			width: 100%;
			height: 950px;
			background-image: url("../../assets/images/soluts/solut0s.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}
	
	
		.page_5 {
			width: 100%;
			min-width: 1360px;
			padding-bottom: 200px;
			background: #FFFFFF;
		}
	
		.page_5_1 {
			width: 1360px;
			margin: 0 auto;
	
	
			.page_5_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: left;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
	
			}
	
			.page_5_1_bot {
				width: 100%;
				height: 410px;
				margin-top: 80px;
				display: flex;
				align-items: center;
				justify-content: space-between;
	
				.page_5_1_bot_left {
					width: 630px;
					height: 100%;
	
					.page_5_img {
						width: 630px;
						height: 410px;
						background-image: url("../../assets/images/soluts/solut0_9.png");
					}
	
					.page_5_box {
						width: 100%;
						text-align: center;
						font-size: 24px;
						margin-top: 50px;
						color: #000000;
	
					}
	
				}
	
				.page_5_1_bot_right {
					width: 640px;
					height: 300px;
	
	
					.food-list {
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
	
						.food-item {
							display: flex;
							align-items: center;
	
							.left-img {
								width: 68px;
								height: 68px;
								display: flex;
								align-items: center;
								justify-content: center;
								margin-right: 20px;
							}
	
							.right-msg {
								text-align: left;
	
								.title {
									font-size: 24px;
									color: #000000;
								}
	
								.content {
									margin-top: 5px;
									color: #ACACAC;
									font-size: 14px;
								}
							}
	
	
	
						}
					}
	
	
	
				}
			}
	
	
		}
	
		.page_6 {
			width: 100%;
			min-width: 1360px;
			padding-top: 150px;
			padding-bottom: 200px;
			background: #FAFAFA;
	
			.page_6_1 {
				width: 1360px;
				height: 600px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;
	
				.page_6_left {
					width: 580px;
					display: flex;
					flex-direction: column;
	
					.left-title {
						font-size: 48px;
						color: #000000;
						margin-bottom: 70px;
					}
	
					.left-list {
						display: flex;
						margin-bottom: 30px;
	
						.item-right {
							display: flex;
							text-align: left;
							flex-direction: column;
	
							.item-title {
								display: flex;
								align-items: center;
								font-size: 24px;
								color: #2F2F2F;
								margin-bottom: 10px;
	
								.item-left {
									margin: 0;
									width: 20px;
									height: 20px;
									border-radius: 50%;
									border: 1px solid #0081CC;
									margin-right: 10px;
								}
							}
	
							.item-msg {
								margin-left: 30px;
								font-size: 16px;
								color: #2F2F2F;
							}
						}
	
					}
				}
	
				.page_6_right {
					width: 580px;
					height: 600px;
					background-image: url("../../assets/images/soluts/solut0_13.png");
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;
				}
	
	
			}
		}
	
	
	
		.page_7 {
			background: #FFFFFF;
			padding-bottom: 100px;
	
			.page_7_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_7_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_7_1_bot {
				width: 100%;
				height: 510px;
				margin-top: 80px;
				background-image: url("../../assets/images/soluts/solut0_14.png");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
	
	
			}
	
		}
	
		.page_8 {
			background: #FAFAFA;
			padding-bottom: 200px;
	
			.page_8_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_8_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #000000;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #0081CC;
				}
	
			}
	
			.page_8_1_bot {
				width: 100%;
				margin-top: 80px;
				display: flex;
				justify-content: space-between;
	
				.page_8_item {
					width: 400px;
					// height: 340px;
					display: flex;
					flex-direction: column;
	
					.top_img {
						width: 100%;
						height: 260px;
						margin-bottom: 30px;
						display: flex;
						align-items: center;
						justify-content: center;
	
						img {
							width: 100%;
							height: 260px;
							object-fit: cover;
						}
					}
	
					.item-list {
						width: 90%;
						margin: 0 auto;
						font-size: 16px;
						color: #2F2F2F;
						display: flex;
						align-items: center;
						margin-bottom: 5px;
	
						.left-icon {
							width: 8px;
							height: 8px;
							margin-right: 10px;
							border-radius: 50%;
							background-color: #D8D8D8;
						}
	
						.right-text {}
					}
	
	
				}
	
	
	
			}
	
		}
	
		.page_9 {
			background: #FFFFFF;
			height: 920px;
			background-image: url("../../assets/images/soluts/solut0_18.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
	
			.page_9_1 {
				width: 1360px;
				margin: 0 auto;
			}
	
			.page_9_1_top {
				padding-top: 100px;
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
	
				.top-title {
					color: #FFFFFF;
					font-size: 48px;
				}
	
				.bot-line {
					margin-top: 20px;
					width: 127px;
					height: 4px;
					background-color: #FFFFFF;
				}
	
			}
	
			.page_9_1_bot {
				width: 100%;
				height: 510px;
				margin-top: 80px;
				display: flex;
				justify-content: space-between;
	
				img {
					width: 200px;
					height: 460px;
					border-radius: 5px;
					object-fit: cover;
				}
	
			}
	
		}
	
		.page_10 {
			padding-bottom: 200px;
	
			.page_10_1 {
				width: 1360px;
				margin: 0 auto;
	
	
				.page_10_1_top {
					margin-top: 100px;
					width: 100%;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
	
					.top-title {
						color: #000000;
						font-size: 48px;
					}
	
					.bot-line {
						margin-top: 20px;
						width: 127px;
						height: 4px;
						background-color: #0081CC;
					}
	
				}
	
				.page_10_1_bot {
					width: 100%;
					margin-top: 80px;
					display: flex;
					justify-content: space-between;
	
					.page_10_item {
						width: 300px;
						height: 420px;
						background: #FFFFFF;
						border: 1px solid #E5E5E5;
						display: flex;
						flex-direction: column;
	
						.top_img {
							width: 100%;
							height: 140px;
							display: flex;
							align-items: center;
							justify-content: flex-start;
							img{
								margin-left: 26px;
							}
						}
	
						.item-name {
							padding-left: 26px;
							margin-bottom: 20px;
							font-size: 24px;
							color: #2F2F2F;
							text-align: left;
						}
	
						.item-list {
							padding-left: 26px;
							font-size: 16px;
							color: #2F2F2F;
							display: flex;
							align-items: center;
							margin-bottom: 10px;
	
							.left-icon {
								width: 8px;
								height: 8px;
								margin-right: 10px;
								border-radius: 50%;
								background-color: #D8D8D8;
							}
	
							.right-text {}
						}
					}
				}
			}
		}
	
		.page_11 {
			width: 100%;
			min-width: 1360px;
			padding-top: 150px;
			padding-bottom: 200px;
			background: #FAFAFA;
	
	
			.page_11_1 {
				width: 1360px;
				height: 600px;
				margin: 0 auto;
	
				.page_11_1_top {
					width: 100%;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
	
					.top-title {
						color: #000000;
						font-size: 48px;
					}
	
					.bot-line {
						margin-top: 20px;
						width: 127px;
						height: 4px;
						background-color: #0081CC;
					}
	
				}
	
				.page_11_1_bot {
					width: 100%;
					margin-top: 80px;
					display: flex;
					align-items: center;
					justify-content: space-between;
	
					.page_11_left {
						width: 710px;
						display: flex;
						flex-wrap: wrap;
	
						.left-list {
							width: 225px;
							height: 150px;
							margin: 0 10px 10px 0;
							background-color: #FFFFFF;
							border-radius: 10px;
							display: flex;
							flex-direction: column;
	
							.item-title {
								display: flex;
								align-items: center;
								font-size: 24px;
								color: #2F2F2F;
								margin: 20px 0 20px 15px;
	
							}
	
							.item-list {
								margin-left: 15px;
								font-size: 16px;
								color: #2F2F2F;
								display: flex;
								align-items: center;
								margin-bottom: 5px;
	
								.left-icon {
									width: 8px;
									height: 8px;
									margin-right: 10px;
									border-radius: 50%;
									background-color: #D8D8D8;
								}
	
								.right-text {}
							}
	
	
						}
					}
	
					.page_11_right {
						width: 600px;
						height: 400px;
						background-image: url("../../assets/images/soluts/solut0_29.png");
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
					}
				}
	
	
	
	
	
	
			}
		}
	
	
	
	
</style>